/* Navbar.css */

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
}

.nav {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 1rem;
}

.nav__item {
    vertical-align: middle;
    padding-top: 17px;
}

.nav__logo {
    color: var(--first-color);
    transition: color 0.4s;
    font-size: var(--h2-font-size);
    font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
    font-size: 2rem;
    color: var(--title-color);
    cursor: pointer;
    margin-top: -20px;
}

.nav__cta {
    background-color: var(--first-color);
    color: var(--title-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0.75rem 1.5rem;
}

@media screen and (max-width: 1150px) {
    .nav__menu {
        position: fixed;
        top: 0;
        right: -100%;
        background-color: hsla(0, 0%, 6%, 0.2);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: 80%;
        height: 100%;
        padding: 6rem 3rem 0;
        transition: right 0.4s;
    }

    .nav__list {
        flex-direction: column !important;
        row-gap: 1rem;
    }
}

@media screen and (max-width: 300px) {
    .nav__menu {
        padding: 4rem 1rem 2rem;
    }

    .nav__list {
        flex-direction: column !important;
        row-gap: 1rem;
    }

    .nav__link,
    .nav__cta {
        font-size: var(--smaller-font-size);
    }

    .nav__cta {
        padding: 8px 16px;
        font-size: 12px;
        margin-top: 1rem;
        width: auto;
        white-space: nowrap;
    }
}

.nav__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    row-gap: 2.5rem;
}

.nav__link {
    font-weight: 500 !important;
    padding-bottom: 30px !important;
    text-decoration: none;
    color: #000;
    transition: color 0.4s;
}

.nav__link:hover {
    color: #4F5D73;
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.show-menu {
    right: 0;
}

.nav__list {
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
}

.nav__menu_mobile {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
}

.active {
    border-bottom-color: #4F5D73 !important;
    border-bottom-width: 4px !important;
    border-bottom-style: solid !important;
    padding-bottom: 30px !important;
}

@media screen and (min-width: 1150px) {
    .nav {
        height: calc(var(--header-height) + 1rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__list {
        flex-direction: row !important;
        column-gap: 2.5rem;
    }
}