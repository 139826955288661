.tcf-panel-card {
    min-height: 480px;
}

.pb-0 {
    color: rgba(74, 53, 206, .7) !important;
}

#fluent-listboxrrr {
    display: none;
}

.fui-TreeItemLayout__main,
.fui-TreeItemLayout {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.f1s2aq7o .r1bx0xiv:hover {
    background-color: unset;
    color: unset;
}

.tcf-available-tags {
    margin-top: 5px;
    margin-bottom: 5px;
}

.channel-picker--info {
    button {
        margin-top: 4px;
    }
}