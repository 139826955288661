.p-4 {
    padding: 0px !important;
}

.p-4 .card-title,
.p-4 .card-subtitle {
    padding-left: 1rem;
    padding-right: 1rem;
}

.p-4 .card-title {
    padding-top: 1rem;
}

.col-md-4 {
    margin-top: 50px;
    margin-left: 20px;
}