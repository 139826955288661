.tcf-card-logo {
    max-height: 80px;      
    width: fit-content;
    margin: 1.5rem 25px 0;
    object-fit: contain;   
    padding: 0 1rem;      
    vertical-align: top;
    width: 50% !important;
}

.card {
    margin-top: 25px;
}

// .card {
//     transition: transform 0.2s ease-in-out;
//     background: white;     
// }

// .card-body {
//     padding: 1.5rem;      
// }

// .card:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
// }


// .card h2 {
//     font-size: 1.75rem;   
//     margin-bottom: 0.5rem;
// }

// .card h4 {
//     font-size: 1rem;     
//     color: #6610f2;       
//     margin-bottom: 1rem;
// }
